import styled from "styled-components"

export const UserInformationWrapper = styled.div`
	.conatinerHeader {
		padding: 16px 16px 0 16px;
		p {
			margin: 0;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			span {
				color: #435363;
				font-size: 14px;
				font-weight: 500;
				line-height: 16.8px;
				&:first-child {
					width: 16px;
					height: 16px;
				}
			}
		}
	}
	.containerData {
		padding: 0 16px;
		p {
			width: 44%;
			display: inline-flex;
			justify-content: flex-start;
			flex-direction: column;
			span {
				&:first-child {
					color: #435363;
					font-size: 14px;
					font-weight: 500;
					line-height: 16.8px;
				}
				&:last-child {
					color: #5b3fff;
					font-size: 14px;
					font-weight: 500;
					line-height: 16.8px;
				}
			}
		}
	}
`
