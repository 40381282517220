import { useTranslation } from "react-i18next"
import { SectionHeaderWrapper } from "./styles"

export default function SectionHeader() {
	const { t } = useTranslation()
	return (
		<SectionHeaderWrapper>
			<p>{t("DM.PRIVATEMESSGASES")}</p>
			<span>{t("DM.PRIVATEMESSGASESDESCRIPTION")}</span>
		</SectionHeaderWrapper>
	)
}
