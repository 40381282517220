import { useTranslation } from "react-i18next"
import { PropertyInformationWrapper } from "./styles"
import HomeIcon from "@assets/svg/design-system/home.svg?react"
import AirbnbIcon from "@assets/svg/airbnb.svg?react"
import GathernIcon from "@assets/svg/GathernIcon2.svg?react"
import BookingIcon from "@assets/svg/BookingIcon.svg?react"
import ChannelAgoda from "@assets/svg/ChannelAgoda.svg?react"
import MsoolIcon from "@assets/svg/msool-icon.svg?react"
import { useRecoilValue } from "recoil"
import { DMDataReservationRequest } from "@store/DM/DMDataReservationRequest"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { useDM } from "@services"
import { ToastMessageContext } from "@context/toast-message-cotnext"
import { DMLiveFeedEventId } from "@store/index"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import { ContentCopyOutlined } from "@mui/icons-material"
import { Box, Snackbar } from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import { IoClose } from "react-icons/io5"

// Extend dayjs with plugins
dayjs.extend(utc)
dayjs.extend(timezone)

interface IProps {
	setIsPopupOpen: Dispatch<SetStateAction<boolean>>
}
export default function PropertyInformation({ setIsPopupOpen }: IProps) {
	/* ------------------------------- Local State ------------------------------ */
	const channals = {
		airbnb: <AirbnbIcon />,
		gathern: <GathernIcon />,
		booking: <BookingIcon />,
		agoda: <ChannelAgoda />,
		other: "Other",
		msool: <MsoolIcon />,
	}
	const [isInstructionsOpen, setIsInstructionsOpen] = useState(false)
	const [snackOpen, setSnackOpen] = useState(false)
	const DMDataReservationRequestValue = useRecoilValue(DMDataReservationRequest)
	const DMLiveFeedEventIdValue = useRecoilValue(DMLiveFeedEventId)
	/* ---------------------------------- Hooks --------------------------------- */
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const start_dateTimeZone =
		DMDataReservationRequestValue?.reservation.start_date.replace(/\[.*?\]/, "")
	const end_dateTimeZone =
		DMDataReservationRequestValue?.reservation.end_date.replace(/\[.*?\]/, "")
	const currantTimeZone = DMDataReservationRequestValue?.reservation.time_zone
	const start_date = dayjs
		.tz(start_dateTimeZone, currantTimeZone)
		.format("YYYY-MM-DD -- h:mm A")

	const end_date = dayjs
		.tz(end_dateTimeZone, currantTimeZone)
		.format("YYYY-MM-DD -- h:mm A")
	/* ------------------------------- API'S Call ------------------------------- */
	const { showError, showSuccess } = useContext(ToastMessageContext)
	const { HandleReservationRequestMutate } = useDM()
	const { mutateAsync: mutateAsyncHandleReservationRequestMutate } =
		HandleReservationRequestMutate({
			onError: (e: any) => {
				try {
					const errObj = e?.response?.data?.errorMessages[0]
					const err = language === "ar" ? errObj?.messageAr : errObj?.messageEn
					showError(err || "حدث خطأ في النظام")
				} catch (e) {
					console.log(e)
				}
			},
			onSuccess: () => {
				showSuccess(t("MANUALRESERVATION.RESERVATIONHASBEENSUCCESSFULLYSAVED"))
				setIsPopupOpen(false)
				window.location.reload()
			},
		})

	const onSubmit = async () => {
		const data = {
			requestStatus: true,
			rejectReason: "0",
		}
		if (!DMLiveFeedEventIdValue?.live_feed_event_id) return
		await mutateAsyncHandleReservationRequestMutate({
			bookingId: DMLiveFeedEventIdValue?.live_feed_event_id,
			data: data,
		})
	}

	const handleCopyClick = () => {
		if (!DMDataReservationRequestValue?.reservation.property_id) return
		navigator.clipboard
			.writeText(DMDataReservationRequestValue?.reservation.property_id)
			.then(() => {
				setSnackOpen(true)
			})
			.catch((err) => {
				console.error("Failed to copy: ", err)
			})
	}
	const action = (
		<Box sx={{ display: "flex", justifyContent: "space-between", paddingX: 5 }}>
			<CheckIcon sx={{ color: "white" }} />
			<Box width={30} />
			<Box sx={{ fontWeight: 900, mt: 0.5 }}>
				{t("COMMON.RESERVATION_COPIED")}
			</Box>
		</Box>
	)
	return (
		<PropertyInformationWrapper>
			<Snackbar
				open={snackOpen}
				autoHideDuration={6000}
				onClose={() => {
					setSnackOpen(false)
				}}
				message=''
				action={action}
				anchorOrigin={{ vertical: "top", horizontal: "left" }}
				sx={{
					position: "absolute",
					backgroundColor: "#5A3FFE",
					borderRadius: 20,
				}}
				ContentProps={{
					sx: {
						background: "#5A3FFE",
						borderRadius: 20,
					},
				}}
			/>
			<div className='conatinerHeader'>
				<p>
					<span>
						<HomeIcon />
					</span>
					<span>{t("DM.PROPERTIEINFO")}</span>
				</p>
			</div>
			<div className='conatainerData'>
				<div className='propertyInfo'>
					<div className='containerPropertyName'>
						<div>
							{
								channals[
									DMDataReservationRequestValue?.channals?.toLowerCase() as
										| "airbnb"
										| "gathern"
										| "booking"
										| "agoda"
										| "other"
										| "msool"
								]
							}
						</div>
						<p>{DMDataReservationRequestValue?.reservation.propertyName}</p>
					</div>
					<div className='containerStatus'>
						{DMDataReservationRequestValue?.reservation.status_type ===
							"pending" && (
							<p className={"columnsContent pendding "}>
								<span></span>
								حجز معلق
							</p>
						)}
					</div>
				</div>
				<div className='reservationNum'>
					<p>
						<span>رقم الحجز</span>
						<div className='containerIcon'>
							<span>
								{DMDataReservationRequestValue?.reservation.property_id}
							</span>
							<div onClick={() => handleCopyClick()}>
								<ContentCopyOutlined sx={{ width: "16px" }} />
							</div>
						</div>
					</p>
				</div>
				<div className='reservationPrice'>
					<p>بيانات التسعير</p>
					<p>
						<span>سعر الليلة:</span>
						<span>
							{" "}
							{DMDataReservationRequestValue?.reservation.nightPrice} ريال
						</span>
					</p>
					<p>
						<span>إجمالي السعر :</span>
						<span>
							{" "}
							{DMDataReservationRequestValue?.reservation.totalPrice} ريال
						</span>
					</p>
				</div>
				<div className='reservationTime'>
					<p>الوقت والتاريخ:</p>
					<p>
						<span>وقت وتاريخ بداية الحجز:</span>
						<span>{start_date ?? ""}</span>
					</p>
					<p>
						<span>وقت وتاريخ نهاية الحجز:</span>
						<span>{end_date ?? ""}</span>
					</p>
				</div>
				<div className='containerActions'>
					<button onClick={() => onSubmit()}>قبول الحجز</button>
				</div>
				<div className='containerCancelReservetion'>
					<div
						className='ContainerLabel'
						onClick={() => setIsInstructionsOpen(true)}
					>
						<p>هل تود أن تقوم بإلغاء الحجز؟</p>
					</div>
				</div>
				{isInstructionsOpen && (
					<div className='instructions'>
						<div className='HeaderSection'>
							<div>
								<p>عزيزي المضيف،</p>
								<p>نود إعلامك بالإجراءات المتعلقة برفض طلبات الحجز:</p>
							</div>
							<div
								className='CloseButton'
								onClick={() => setIsInstructionsOpen(false)}
							>
								<IoClose />
							</div>
						</div>
						<div className='body'>
							<ol>
								<li>
									طلب الحجز هذا سيتم رفضه تلقائيًا خلال 24 ساعة إذا لم تتخذ أي
									إجراء.
								</li>
								<li>
									هذا الإجراء متوافق مع سياسة Airbnb لضمان تجربة حجز سلسة لجميع
									الأطراف.
								</li>
							</ol>

							<div>
								<b>إذا كنت ترغب في إدارة هذا الحجز بشكل مباشر،</b>
								<br />
								<span>يمكنك القيام بذلك من خلال حسابك على</span>
								<b>Airbnb:</b>
							</div>

							<ol>
								<li>انتقل إلى قسم الرسائل أو الحجوزات</li>
								<li>ابحث عن الطلب المعني وقم برفضه.</li>
							</ol>

							<div>
								<span>
									تذكر أنه يمكنك دائمًا قبول طلبات الحجز مباشرة من خلال نظام
									مسؤول. إذا كانت لديك أي أسئلة أو استفسارات، فلا تتردد في
								</span>{" "}
								<b className='blue'>
									<a href='mailto:support@msool.io'>
										التواصل مع فريق الدعم الخاص بنا.
									</a>
								</b>
							</div>
						</div>
					</div>
				)}
			</div>
		</PropertyInformationWrapper>
	)
}
