import styled from "styled-components"

export const SectionMessagesWrapper = styled.div`
	overflow: hidden;
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr;
	gap: 18px;
	padding-inline: 4px;
	> p {
		color: #435363;
		font-size: 24px;
		font-weight: 700;
		line-height: 28.8px;
		margin: 0;
	}
	.containerList {
		overflow-y: scroll;
		p {
			margin: 0;
		}
		.containerNoData {
			padding: 16px;
			background-color: #f8fafc;
			border-radius: 16px;
			height: 100%;
			p {
				&.noData {
					color: #8e98a1;
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}

		.containerCardMessageInfo,
		.active {
			display: grid;
			grid-template-columns: 20px 1fr auto;
			grid-template-rows: 1fr;
			gap: 8px;
			padding: 8px;
			border-radius: 8px;
			height: 50px;
			.containerIcon {
				width: 20px;
				height: 20px;
				overflow: hidden;
				svg {
					width: 100%;
					height: 100%;
				}
			}

			.containerDataInfo {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				p {
					margin: 0;
					&:first-child {
						color: #000000;
						font-size: 14px;
						font-weight: 700;
						line-height: 20px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					&:last-child {
						color: #00000066;
						font-size: 12px;
						font-weight: 400;
						line-height: 16px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
			}

			.containerTime {
				p {
					color: #00000066;
					font-size: 12px;
					font-weight: 400;
					line-height: 16px;
				}
			}
		}

		.active {
			background-color: #f7f6ff;
		}
	}
`
