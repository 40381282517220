import { useTranslation } from "react-i18next"
import { SectionMessagesWrapper } from "./styles"
import { useDM } from "@services"
import { Dispatch, Fragment, SetStateAction } from "react"
import AirbnbIcon from "@assets/svg/airbnb.svg?react"
import GathernIcon from "@assets/svg/GathernIcon2.svg?react"
import BookingIcon from "@assets/svg/BookingIcon.svg?react"
import ChannelAgoda from "@assets/svg/ChannelAgoda.svg?react"
import MsoolIcon from "@assets/svg/msool-icon.svg?react"
import dayjs from "dayjs"

interface IProps {
	setSelectedMessageId: Dispatch<SetStateAction<string | undefined>>
	selectedMessageId: string | undefined
}
export default function SectionMessages({
	setSelectedMessageId,
	selectedMessageId,
}: IProps) {
	const channals = {
		airbnb: <AirbnbIcon />,
		gathern: <GathernIcon />,
		booking: <BookingIcon />,
		agoda: <ChannelAgoda />,
		msool: <MsoolIcon />,
	}
	const { GetAllMessageThreadsQuery } = useDM()
	const { data: AllMessageThreads } = GetAllMessageThreadsQuery()
	const { t } = useTranslation()

	return (
		<SectionMessagesWrapper>
			<p>{t("DM.MESSAGES")}</p>
			<div className='containerList'>
				{AllMessageThreads?.data.result.length === 0 ||
				!AllMessageThreads?.data.result ? (
					<div className='containerNoData'>
						<p className='noData'>{t("DM.NOMESSAGES")}</p>
					</div>
				) : (
					<Fragment>
						{AllMessageThreads.data.result.map((item) => {
							return (
								<div
									className={`${item.id === selectedMessageId ? "active" : "containerCardMessageInfo"}`}
									key={item.id}
									onClick={() => setSelectedMessageId(item.id)}
								>
									<div className='containerIcon'>
										<div>
											{
												channals[
													item.attributes.provider.toLowerCase() as
														| "airbnb"
														| "gathern"
														| "booking"
														| "agoda"
														| "msool"
												]
											}
										</div>
									</div>
									<div className='containerDataInfo'>
										<p>{item.attributes.title}</p>
										<p>{item.attributes.lastMessage}</p>
									</div>
									<div className='containerTime'>
										<p>{dayjs(item.attributes.insertedAt).format("HH:MM")}</p>
									</div>
								</div>
							)
						})}
					</Fragment>
				)}
			</div>
		</SectionMessagesWrapper>
	)
}
