import { atom } from "recoil"
import { ATOMS_KEYS } from "../atom-keys"
interface IDMData {
	checkin_date: string | undefined
	currency: string | undefined
	guest_name: string | undefined
	listing_name: string | undefined
	property_id: string | undefined
	room_type_id: string | undefined
}
export const DMData = atom<IDMData | undefined>({
	key: ATOMS_KEYS.DMDATA,
	default: undefined,
})
