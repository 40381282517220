export const ATOMS_KEYS = {
	CONTROLEADVANCEDSETTINGS: "CONTROLEADVANCEDSETTINGS",
	REINITIALIZECALENDAR: "REINITIALIZECALENDAR",
	ISMONTHLYVIEW: "ISMONTHLYVIEW",
	GETDAYSOFWEEK: "GETDAYSOFWEEK",
	CONTROLEBOOKINGDETAILS: "CONTROLEBOOKINGDETAILS",
	GITBOOKINGINFO: "GITBOOKINGINFO",
	CLASSFICATION: "CLASSFICATION",
	DMDATA: "DMDATA",
	DMLIVEFEEDEVENTID: "DMLIVEFEEDEVENTID",
	DMDATARESERVATIONREQUEST: "DMDATARESERVATIONREQUEST",
}
