import { IoClose } from "react-icons/io5"
import { PopupDeleteWrapper } from "./styles"
import { FormControlLabel, Radio, RadioGroup } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useDM } from "@services"
import { Dispatch, SetStateAction, useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useRecoilValue } from "recoil"
import { DMLiveFeedEventId } from "@store/index"
import { ToastMessageContext } from "@context/toast-message-cotnext"
type FormValues = {
	rejectReason: string
}
interface IProps {
	setIsPopupOpen: Dispatch<SetStateAction<boolean>>
}
export default function PopupDelete({ setIsPopupOpen }: IProps) {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const { showError, showSuccess } = useContext(ToastMessageContext)
	const DMLiveFeedEventIdValue = useRecoilValue(DMLiveFeedEventId)
	const { useGetRejectReasonQuery, HandleReservationRequestMutate } = useDM()
	const { data: GetRejectReasonQuery } = useGetRejectReasonQuery({
		lang: language === "ar" ? "A" : "E",
	})
	const { mutateAsync: mutateAsyncHandleReservationRequestMutate } =
		HandleReservationRequestMutate({
			onError: (e: any) => {
				try {
					const errObj = e?.response?.data?.errorMessages[0]
					const err = language === "ar" ? errObj?.messageAr : errObj?.messageEn
					showError(err || "حدث خطأ في النظام")
				} catch (e) {
					console.log(e)
				}
			},
			onSuccess: () => {
				showSuccess(t("MANUALRESERVATION.RESERVATIONHASBEENSUCCESSFULLYSAVED"))
				setIsPopupOpen(false)
				window.location.reload()
			},
		})

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm<FormValues>()
	const onSubmit = async (value: FormValues) => {
		const data = {
			requestStatus: false,
			rejectReason: value.rejectReason,
		}
		if (!DMLiveFeedEventIdValue?.live_feed_event_id) return
		const response = await mutateAsyncHandleReservationRequestMutate({
			bookingId: DMLiveFeedEventIdValue?.live_feed_event_id,
			data: data,
		})
		console.log("response", response)
	}

	useEffect(() => {
		console.log(GetRejectReasonQuery?.result)
	}, [GetRejectReasonQuery])
	return (
		<PopupDeleteWrapper>
			<div className='containerQuestions'>
				<div className='header'>
					<p>اختر سبب الرفض</p>
					<button onClick={() => setIsPopupOpen(false)}>
						<IoClose />
					</button>
				</div>
				<div className='Body'>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Controller
							control={control}
							name='rejectReason'
							render={({ field }) => (
								<RadioGroup {...field}>
									{GetRejectReasonQuery?.result.map((item) => (
										<div className='containerItem' key={item.id}>
											<FormControlLabel
												value={item.id}
												control={<Radio />}
												label=''
											/>
											<p>{item.name}</p>
										</div>
									))}
								</RadioGroup>
							)}
						/>
						{errors.rejectReason && (
							<p className='errorMessage'>{errors?.rejectReason.message}</p>
						)}
						<div className='Footer'>
							<button type='submit'>رفض الحجز</button>
						</div>
					</form>
				</div>
			</div>
		</PopupDeleteWrapper>
	)
}
