import { Grid } from "@mui/material"
import SectionHeader from "./SectionHeader"
import { DirectMessagesWrapper } from "./styles"
import SectionMessages from "./SectionMessages"
import SectionChats from "./SectionChats"
import SectionUserDetails from "./SectionUserDetails"
import { useState } from "react"
import PopupDelete from "./popupDelete"

export default function DirectMessages() {
	const [selectedMessageId, setSelectedMessageId] = useState<string>()
	const [isPopupOpen, setIsPopupOpen] = useState(false)

	return (
		<DirectMessagesWrapper>
			{isPopupOpen && <PopupDelete setIsPopupOpen={setIsPopupOpen} />}
			<Grid container gap={"24px"}>
				<Grid item xs={12}>
					<SectionHeader />
				</Grid>
				<Grid item xs={12}>
					<div className='containerCard'>
						<Grid container>
							<Grid item xs={3}>
								<SectionMessages
									selectedMessageId={selectedMessageId}
									setSelectedMessageId={setSelectedMessageId}
								/>
							</Grid>
							<Grid item xs={selectedMessageId ? 6 : 9}>
								<SectionChats selectedMessageId={selectedMessageId} />
							</Grid>
							{selectedMessageId && (
								<Grid item xs={3}>
									<SectionUserDetails setIsPopupOpen={setIsPopupOpen} />
								</Grid>
							)}
						</Grid>
					</div>
				</Grid>
			</Grid>
		</DirectMessagesWrapper>
	)
}
