import styled from "styled-components"

export const ChatMessagesWrapper = styled.div`
	height: 100%;
	.guest {
		padding: 10px;
		display: flex;
		justify-content: flex-end;
		> div {
			width: 50%;
			padding: 16px 20px;
			border-radius: 16px 16px 16px 4px;
			background-color: #e5ecf6;
			font-size: 12px;
			font-weight: 500;
			line-height: 20px;
			color: #435363;
			text-align: left;
			p {
				white-space: normal;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
	.property {
		padding: 10px;
		display: flex;
		justify-content: flex-start;
		> div {
			width: 50%;
			padding: 16px 20px;
			background-color: #eeebff;
			font-size: 12px;
			font-weight: 500;
			line-height: 20px;
			color: #435363;
			border-radius: 16px 16px 4px 16px;
			text-align: right;
			p {
				white-space: normal;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
`
