import { useTranslation } from "react-i18next"
import { UserInformationWrapper } from "./styles"
import TagUser from "@assets/svg/TagUser.svg?react"
import { useRecoilValue } from "recoil"
import { DMData } from "@store/index"

export default function UserInformation() {
	const { t } = useTranslation()
	const DMDataValue = useRecoilValue(DMData)

	const data = [
		{
			title: "الاسم :",
			value: DMDataValue?.guest_name,
		},
		{
			title: "اسم العقار:",
			value: DMDataValue?.listing_name,
		},
		{
			title: "وقت الدخول:",
			value: DMDataValue?.checkin_date,
		},
	]
	return (
		<UserInformationWrapper>
			<div className='conatinerHeader'>
				<p>
					<span>
						<TagUser />
					</span>
					<span>{t("NEW_LOCKS.GUEST_DETAILS")}</span>
				</p>
			</div>
			<div className='containerData'>
				{data.map((item, index) => {
					return (
						<p key={index}>
							<span>{item.title}</span>
							<span>{item.value}</span>
						</p>
					)
				})}
			</div>
		</UserInformationWrapper>
	)
}
