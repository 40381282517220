import CustomButton from "@Common/CustomButton"
import { SectionHeaderWrapper } from "./styles"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

export default function SectionHeader() {
	const { t } = useTranslation()
	const navigate = useNavigate()
	return (
		<SectionHeaderWrapper>
			<div>
				<p>إدارة الملاك</p>
				<p>مساحة تعينك على إدارة ملاكك</p>
			</div>
			<div>
				<CustomButton
					label={"إضافة"}
					withBG
					customPadding='8px 90px'
					onClick={() => navigate("/add-owner")}
				/>
			</div>
		</SectionHeaderWrapper>
	)
}
