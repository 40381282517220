import { Grid } from "@mui/material"
import SectionHeader from "./SectionHeader"
import SectionTable from "./SectionTable"

export default function OwnerManagemnt() {
	return (
		<div>
			<Grid container gap={"24px"}>
				<Grid item xs={12}>
					<SectionHeader /> 
				</Grid>
				<Grid item xs={12}>
					<SectionTable />
				</Grid>
			</Grid>
		</div>
	)
}
