import styled from "styled-components"

export const SectionHeaderWrapper = styled.div`
	p {
		margin: 0;
		color: #130080;
		font-size: 20px;
		font-weight: 700;
		line-height: 24px;
	}
	span {
		color: #adbac7;
		font-size: 16px;
		font-weight: 400;
		line-height: 19.2px;
	}
`
