import { useApi } from "./useApi"
import {
	GetOwnerUrl,
	GetOwnerByIdUrl,
	UpdateOwnerUrl,
	DeleteOwnerUrl,
	CreateOwnerUrl,
	GetPropertiesWithNoOwnerUrl,
	GetRentalAndExpensesByOwnerIdUrl
} from "../utils/constants"
import { useMutation, useQuery } from "@tanstack/react-query"


const useOwnerManagment = () => {
	const { apiPrivate } = useApi()
	/* ------------------------------ getOwners ----------------------------- */
	const getOwners = async (params: {
		pageSize: string
		pageNumber: string
		search?: string
	}) => {
		const response: { data: any } = await apiPrivate.get(
			GetOwnerUrl + `?pageSize=1&pageNumber=1`)
		return response
	}

	const GetOwnersQuery = (data: {
		pageSize: string
		pageNumber: string
		search?: string
	}) =>
		useQuery({
			queryKey: [GetOwnerUrl, data],
			queryFn: () => getOwners(data),
		})
	/* ------------------------------ getPropertiesWithNoOwners ----------------------------- */
	const getPropertiesWithNoOwners = async (params: {
		pageSize: string
		pageNumber: string
		search?: string
	}) => {
		const response: { data: any } = await apiPrivate.get(
			GetPropertiesWithNoOwnerUrl + `?pageSize=${params?.pageSize}&pageNumber=${params?.pageNumber}`)
		return response
	}

	const getPropertiesWithNoOwnersQuery = (data: {
		pageSize: string
		pageNumber: string
		search?: string
	}) =>
		useQuery({
			queryKey: [GetPropertiesWithNoOwnerUrl, data],
			queryFn: () => getPropertiesWithNoOwners(data),
		})
	/* ------------------------------ GetRentalAndExpensesByOwnerId ----------------------------- */
	const GetRentalAndExpensesByOwnerId = async (id: string) => {
		const response: { data: any } = await apiPrivate.get(
			GetRentalAndExpensesByOwnerIdUrl + `/${id}`)
		return response
	}

	const GetRentalAndExpensesByOwnerIdQuery = (id: string) =>
		useQuery({
			queryKey: [GetRentalAndExpensesByOwnerIdUrl, id],
			queryFn: () => GetRentalAndExpensesByOwnerId(id),
		})
	/* ------------------------- GetOwnerByID ------------------------- */
	const getOwnerByID = async (ownerId: string) => {
		const response: { data: any } = await apiPrivate.get(
			`${GetOwnerByIdUrl}/${ownerId}`
		)

		return response
	}

	const GetOwnerByIdQuery = (ownerId: string) =>
		useQuery({
			queryKey: [GetOwnerByIdUrl, ownerId],
			queryFn: () => getOwnerByID(ownerId),
		})


	/* -------------------------- AddOwner -------------------------- */
	const AddOwner = async (body: any) => {
		const data: any = await apiPrivate.post(
			CreateOwnerUrl,
			body
		)
		return data
	}

	const CreateOwnerMutate = ({
		onError,
	}: {
		onError?: (error: any) => void
	}) =>
		useMutation({
			mutationFn: (body: any) => AddOwner(body),
			onError: (error) => onError && onError(error),
		})
	/* -------------------------- updateOwner -------------------------- */
	const updateOwner = async (body: any) => {
		const data: any = await apiPrivate.put(
			`${UpdateOwnerUrl}/${body.id}`,
			body
		)
		return data
	}

	const UpdateOwnerMutate = ({ onError }: {
		onError?: (error: any) => void
	}) =>
		useMutation({
			mutationFn: (body: any) => updateOwner(body),
			onError: (error) => onError && onError(error),
		})

	/* ----------------------------- Delete owner ---------------------------- */
	const deleteOwner = async (id: number) => {
		const data: any = await apiPrivate.delete(
			`${DeleteOwnerUrl}/${id}`
		)
		return data
	}
	const DeleteOwnerMutate = () =>
		useMutation({
			mutationFn: (id: number) => deleteOwner(id),
		})

	return {
		GetOwnersQuery,
		CreateOwnerMutate,
		DeleteOwnerMutate,
		GetOwnerByIdQuery,
		UpdateOwnerMutate,
		getPropertiesWithNoOwnersQuery,
		GetRentalAndExpensesByOwnerIdQuery
	}
}
export { useOwnerManagment }
