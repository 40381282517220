import { Box, Button, Grid, Typography, styled } from "@mui/material"
import { SectionTableWrapper } from "./styles"
import CustomTable from "@Common/CustomTable"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import AirbnbIcon from "@assets/svg/airbnb.svg?react"
import GathernIcon from "@assets/svg/GathernIcon2.svg?react"
import BookingIcon from "@assets/svg/BookingIcon.svg?react"
import ChannelAgoda from "@assets/svg/ChannelAgoda.svg?react"
import MsoolIcon from "@assets/svg/msool-icon.svg?react"
import EyeLine from "@assets/svg/EyeLine.svg?react"
import TrashLine from "@assets/svg/TrashLine.svg?react"
import PenLine from "@assets/svg/PenLine.svg?react"
import FormInputSearch from "./formInputSearch"
import { CustomNoRowsOverlay } from "../CustomNoRowsOverlay"
import { useCategoryMangment, useOwnerManagment } from "@services"
import PopupDeleteCategories from "../popupDeleteCategories"
import PopupViewInnerCategory from "../popupViewInnerCategory"
import PopupEditCategory from "../popupEditCategory"
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { useNavigate } from "react-router-dom"
import React from "react"

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} placement="left"   PopperProps={{ style: {marginTop:-10,padding:0,zIndex:1000 } }} />
))(({ theme }) => ({
	
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor:'rgba(255, 255, 255, 1)',
		//boxShadow:'box-shadow: 0px 9.08px 21.18px -3.03px rgba(234, 234, 253, 0.2)',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
		width:'159px',
		zindex:1000000000

	},
	tooltipPlacementBottom: { // this part will change the distance
        margin: '-10px 100', 
		zindex:1000000000
    },

}));
const RenderOwnerProperties = ({ id = 0, data = [] }: any) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const [isHover, setIsHover] = useState(false)
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const RenderTitle = ()=>{
		return <p className='columnsContent' >
		{`${data[0]?.name}${data?.length > 1 ? ',...': ''}`}
		{data?.length>1 && <span style={{color:'rgba(91, 63, 255, 1)',fontWeight:600,marginRight:'-10px'}} >{`+${data?.length-1}`}</span>}
		</p>
	}
	if (data?.length == 0) return null
	if(data?.length==1) return <RenderTitle />
	return <HtmlTooltip
		title={
			<Box sx={{
				
				height: '100%', 
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				pt:1,
				backgroundColor:' rgba(234, 234, 253, 0.08)'

			}} >						
			{data.map((x: any, i: any) => {
				return (
					<Box

						key={i} sx={{
							display: 'flex',
							alignItems: 'center',
							mb:1
							

						}} >
						<Box component="img" sx={{
							width: '17px',
							height: '17px',
							borderRadius: '1px'
						}} src={x?.image} />
						<Typography sx={{
							fontSize: '13px',
							fontWeight: 500,
							lineHeight: '14px',
							
							color: 'rgba(98, 87, 131, 1)',
							mx: 0.8

						}} >
							{x?.name}
						</Typography>
					</Box>
				)
			})}
			</Box>
		}
	>
		<p className='columnsContent' >
		{`${data[0]?.name}${data?.length > 1 ? ',...': ''}`}
		{data?.length>1 && <span style={{color:'rgba(91, 63, 255, 1)',fontWeight:600,marginRight:'-10px'}} >{`+${data?.length-1}`}</span>}
		</p>
	</HtmlTooltip>

	return <ClickAwayListener onClickAway={() => {
		setIsHover(false)
	}} >
		<p
			className='columnsContent'

			style={{ textAlign: `${language === "ar" ? "right" : "left"}` }}
		>

			{`${data[0]?.name}${data?.length > 1 ? ',...' : ''}`}

			{data?.length > 1 ? <Box component="span"
				onClick={() => setIsHover(!isHover)}
				sx={{
					color: 'rgba(91, 63, 255, 1)',
					fontSize: '12px',
					fontWeight: 700,
					cursor: 'pointer'
				}}
				aria-owns={isHover ? 'mouse-over-popover' : undefined}
				aria-haspopup="true"

			>{`+${data?.length - 1}`}</Box> : null}
			<Popover
				id="mouse-over-popover"
				sx={{ pointerEvents: 'none' }}
				open={isHover}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 70 - (50 * id), //70 25  -20
					horizontal: 450,
				}}
				onClose={() => {
					setIsHover(false)
				}}

			//disableRestoreFocus
			>
				<Box sx={{
					width: '200px',
					maxHeight: false ? '55px' : '111px',
					padding: '6px',
					borderRadius: '8px',

					py: 1,
					//backgroundColor: 'red',
					//boxShadow: 'box-shadow: 0px 9.08px 21.18px -3.03px rgba(234, 234, 253, 0.2)',
					//position: 'absolute',

					zIndex: 9999000,
					//top: 30,

				}} >
					<Box sx={{
						height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center',
					}} >						{data.map((x: any, i: any) => {
						return (
							<Box

								key={i} sx={{
									display: 'flex',
									mb: 1.5,
									alignItems: 'center',

								}} >
								<Box component="img" sx={{
									width: '17px',
									height: '17px',
									borderRadius: '1px'
								}} src={x?.image} />
								<Typography sx={{
									fontSize: '14px',
									fontWeight: 400,
									lineHeight: '14px',
									color: 'rgba(98, 87, 131, 1)',
									mx: 0.8

								}} >
									{x?.name}
								</Typography>
							</Box>
						)
					})}
					</Box>

				</Box></Popover>
		</p>
	</ClickAwayListener>
}

export default function SectionTable() {
	const [getSearchCategryInputValue, setSearchCategryInputValue] =
		useState<string>()
	const [innerViewCategory, setInnerViewCategory] = useState(false)
	const [deleteCategories, setDeleteCategories] = useState(false)
	const [editCategory, setEditCategory] = useState(false)
	const [activePage, setActivePage] = useState(1)
	const [perPage, onPerpageChange] = useState("20")
	const { GetCategoriesQuery } = useCategoryMangment()
	const { GetOwnersQuery } = useOwnerManagment()
	const [recordData, setRecordData] = useState<{
		id: number
		categoryId: number
		categoryName: string
		propertyCounts: number
		channals: number[]
	}>()

	const { data: owners, refetch } = GetOwnersQuery({
		pageNumber: activePage.toString(),
		pageSize: perPage,
		search: getSearchCategryInputValue,
	})

	const {
		t,
		i18n: { language },
	} = useTranslation()
	const navigate = useNavigate()

	const columns = [
		{
			field: "firstName",
			headerName: 'الاسم الاول',
			flex: 1,
			renderCell: (params: any) => (
				<p
				onClick={(r:any)=>{
					navigate(`/owner-insights/${params?.row?.id}`)
				}}
					className='columnsContent'
					style={{ textAlign: `${language === "ar" ? "right" : "left"}`,cursor:'pointer' }}
				>
					{params.row.firstName}
				</p>
			),
		},
		{
			field: "lastName",
			headerName: 'الاسم الاخير',
			flex: 1,
			renderCell: (params: any) => (
				<p
				onClick={(r:any)=>{
					navigate(`/owner-insights/${params?.row?.id}`)
				}}
					className='columnsContent'
					style={{ textAlign: `${language === "ar" ? "right" : "left"}`,cursor:'pointer' }}
				>
					{params.row.lastName}
				</p>
			),
		},
		{
			field: "phoneNumber",
			headerName: 'رقم الجوال',
			flex: 1,
			renderCell: (params: any) => (
				<p
					className='columnsContent'
					style={{ textAlign: `${language === "ar" ? "right" : "left"}` }}
				>
					{params.row.phoneNumber}

				</p>
			),
		},
		{
			field: "property",
			headerName: 'العقارات المملوكة',
			flex: 1,
			renderCell: (params: any) =><Box sx={{width:100,height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}} >
				 <RenderOwnerProperties id={params?.row?.indx} data={params?.row?.propertiesNameRelatedToOwner} />
			</Box>
		},
		{
			flex: 1,
			field: "Actions",
			headerName: "",
			renderCell: (params: any) => (
				<p className='columnsActions'>
					<div
						onClick={() => {
							navigate(`/owner-insights/${params?.row?.id}`)
						}}
					>
						<EyeLine />
					</div>
					<div
					style={{display:'block'}}
						onClick={() => {
						
							setDeleteCategories(true)
							setRecordData(params.row)
						}}
					>
						<TrashLine />
					</div>
					<div
						onClick={() => {
							navigate(`/owner-insights/${params?.row?.id}`,{ state: { fromUpdate: true} })
						}}
					>
						<PenLine />
					</div>
				</p>
			),
		},
	]

	const rows = useMemo(() => {

		const data = owners?.data.result.map((item: any, i: number) => {
			return {
				indx: i,
				id: item.id,
				firstName: item?.firstName,
				lastName: item?.lastName,
				phoneNumber: item?.phoneNumber,
				propertiesNameRelatedToOwner: item?.propertiesRelatedToOwner,
			}
		})
		return data ?? []
	}, [owners])

	return (
		<SectionTableWrapper>
			{deleteCategories && (
				<PopupDeleteCategories
					setDeleteCategories={setDeleteCategories}
					recordData={recordData}
					onDeleteSuccess={() => {
						setDeleteCategories(false)
						refetch()

					}}
				/>
			)}


			<Grid container gap={"24px"}>

				<Grid item xs={12}>
					<CustomTable 
					
						columns={columns}
						HeaderTheTable="قائمة الملاك"
						rows={rows ?? []} 
						totalPages={1}
						setActivePage={setActivePage}
						// onRowClick={(r:any)=>{
						// 	navigate(`/owner-insights/${r?.id}`)
						// }}
						activePage={activePage}
						onPerpageChange={onPerpageChange}
						perPage={perPage}
						CustomNoRowsOverlay={CustomNoRowsOverlay}
						listPerPagesNumber={[
							{
								label: "20",
								value: "20",
							},
							{
								label: "50",
								value: "50",
							},
							{
								label: "100",
								value: "100",
							},
						]}
					/> 
				</Grid>
			</Grid>
		</SectionTableWrapper>
	)
}
