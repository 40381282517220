/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
import {
  GetAllOperatorURL,
  AddLockURL,
  UnlockURL,
  SetAutoTimeLockURL,
  UpdateLockIdURL,
  GeneratePassCodeForLockURL,
  GetActivePasscodeURL,
  GetPassCodeHistoryURL,
  GetLockStateURL,
  getLockBatteryURL,
  GetAllLocksURL,
  DeleteLockURL,
  GetLocksControlURL,
  RequestNewOperatorURL,
  GetPropertyDetailsURL,
  DisablePassCode,
  GetLockDetailsUrl,
  getLockStatusURL,
  TuyaQrURL
} from '../utils/constants';  
import { useApi } from './useApi';
import { useMutation, useQuery } from '@tanstack/react-query';

const useLock = () => {
  const { apiPrivate } = useApi();
  const getTuyaQRCODE = async () => {
    const result = await apiPrivate.get(TuyaQrURL);
    return result.data?.result;
  };

  const getAllOperator = async () => {
    const result = await apiPrivate.get(GetAllOperatorURL);
    return result.data?.result;
  };
  const disablePassCode = async (body:any) => {
    const result = await apiPrivate.delete(`${DisablePassCode}?passcode=${body?.code}&lockId=${body.id}`); 
    return result.data;
  };
  const getLockDetails = async (id='') => {
    const result = await apiPrivate.get(`${GetLockDetailsUrl}/${id}`); 
    return result.data?.result;
  };

  const requestNewOperator = async (body: any) => {
    const { data } = await apiPrivate.post(`${RequestNewOperatorURL}`, body);
    return data;
  };

  const getLocksControl = async (pageSize=0,pageNumber=0) => {
    let url = GetLocksControlURL
    
    if(pageSize!==0){
      url = url + `?pageSize=${pageSize}&pageNumber=${pageNumber}`
    }
    const result = await apiPrivate.get(url);
    return result.data
  };

  const getAllLocksList = async (pageSize=0,pageNumber=0) => { 
    let url = GetAllLocksURL
    
    if(pageSize!==0){
      url = url + `?pageSize=${pageSize}&pageNumber=${pageNumber}`
    }
    const result = await apiPrivate.get(url);
    return result.data;
  };

  const getPropertyDetails = async (id: any) => {
    const result = await apiPrivate.get(`${GetPropertyDetailsURL}/${String(id)}`);
    return result.data;
  };

  const AddLock = async (body: any) => {
    let link;
    if (body?.username && body?.password) {
      link = `${AddLockURL}?keyOperator=${body?.keyOperator}&lockId=${body?.lockId}&propertyId=${body?.propertyId}&username=${body?.username}&password=${body?.password}`;
    } else {
      link = `${AddLockURL}?keyOperator=${body?.keyOperator}&lockId=${body?.lockId}&propertyId=${body?.propertyId}`;
    }
    const { data } = await apiPrivate.get(`${link}`);
    return data;
  };

  const unlock = async (body: any) => {
    const { data } = await apiPrivate.get(`${UnlockURL}?lockId=${body?.lockId}`);
    return data;
  };

  const setAutoTimeLockURL = async (body: any) => {
    const { data } = await apiPrivate.get(`${SetAutoTimeLockURL}?lockId=${String(body?.lockId)}`);
    return data;
  };

  const updateLockId = async (body: any) => {
    const { data } = await apiPrivate.put(`${UpdateLockIdURL}/${body.id}`, body);
    return data;
  };

  const deleteLock = async (body: any) => {
    const { data } = await apiPrivate.delete(`${DeleteLockURL}/${body.lockId}`, body);
    return data;
  };

  const generatePassCodeForLock = async (body: any) => {
    const { data } = await apiPrivate.post(`${GeneratePassCodeForLockURL}?lockId=${String(body?.lockId)}`, body);
    return data;
  };

  const getActivePasCode = async (id: any) => {
    const result = await apiPrivate.get(`${GetActivePasscodeURL}/${String(id)}`);
    return result.data;
  };

  const getPassCodeHistory = async (id: any) => {
    const result = await apiPrivate.get(`${GetPassCodeHistoryURL}/${String(id)}`);
    return result.data;
  };

  const getLockState = async (id: any) => {
    const result = await apiPrivate.get(`${GetLockStateURL}/${id}`);
    return result.data;
  };

  const getLockBattery = async (id: any) => {
    const result = await apiPrivate.get(`${getLockBatteryURL}/${id}`);
    return result.data;
  };
  const getLockStatus = async (id: any) => {
    const result = await apiPrivate.get(`${getLockStatusURL}?lockId=${String(id)}`); 
    return result.data?.result;
  };
  const getLockStatusQuery = (id: string) =>
  useQuery({
    enabled: Boolean(id),
    queryKey: [getLockStatusURL, id],
    queryFn: ({ queryKey }) => getLockStatus(queryKey[1]),
  });
  const getAllOperatorListQuery = () => useQuery({ queryKey: ['getAllOperator'], queryFn: getAllOperator });

  const getAllLocksListQuery = (pageSize=0,pageNumber=0) => useQuery({ queryKey: ['GetAllLocksURL',pageSize,pageNumber], queryFn:()=> getAllLocksList(pageSize,pageNumber) });

  const getLocksControlQuery = (pageSize=0,pageNumber=0) => useQuery({ queryKey: ['GetLocksControlURL',pageSize,pageNumber], queryFn:()=> getLocksControl(pageSize,pageNumber) });

  const getPropertyDetailsQuery = (id: string) =>
    useQuery({
      enabled: Boolean(id),
      queryKey: [GetPropertyDetailsURL, id],
      queryFn: ({ queryKey }) => getPropertyDetails(queryKey[1]),
    });

  const getActivePassCodeQuery = (id: string) =>
    useQuery({
      enabled: Boolean(id),
      queryKey: [GetActivePasscodeURL, id],
      queryFn: ({ queryKey }) => getActivePasCode(queryKey[1]),
    });

  const getPassCodeHistoryQuery = (id: string) =>
    useQuery({
      enabled: Boolean(id),
      queryKey: [GetPassCodeHistoryURL, id],
      queryFn: ({ queryKey }) => getPassCodeHistory(queryKey[1]),
    });
    const geQrCodeQuery = () =>
    useQuery({
      enabled:true,
      queryKey: [TuyaQrURL],
      queryFn: ({ queryKey }) => getTuyaQRCODE(),
    });
  const getLockStateQuery = (id: string) =>
    useQuery({
      enabled: Boolean(id),
      queryKey: [GetLockStateURL, id],
      queryFn: ({ queryKey }) => getLockState(queryKey[1]),
    });
    const getLockDetailsQuery = (id: string) =>
    useQuery({
      enabled: Boolean(id),
      queryKey: [GetLockDetailsUrl, id],
      queryFn: ({ queryKey }) => getLockDetails(queryKey[1]),
    });

  const getLockBatteryQuery = (id: string) =>
    useQuery({
      enabled: Boolean(id),
      queryKey: [getLockBatteryURL, id],
      queryFn: ({ queryKey }) => getLockBattery(queryKey[1]),
    });

  const addLockMutate = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
    useMutation({
      mutationFn: (body: any) => AddLock(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });

  const deleteLockMutate = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
    useMutation({
      mutationFn: (body: any) => deleteLock(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });
    const disablePassCodeMutate = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
    useMutation({
      mutationFn: (body: any) => disablePassCode(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });

  const unlockMutate = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
    useMutation({
      mutationFn: (body: any) => unlock(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });

  const setAutoTimeLockURLMutate = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
    useMutation({
      mutationFn: (body: any) => setAutoTimeLockURL(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });

  const updateLockIdMutate = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
    useMutation({
      mutationFn: (body: any) => updateLockId(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });

  const generatePassCodeForLockMutate = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
    useMutation({
      mutationFn: (body: any) => generatePassCodeForLock(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });

  const requestNewOperatorMutate = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) =>
    useMutation({
      mutationFn: (body: any) => requestNewOperator(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });

  return {
    getAllOperatorListQuery,
    addLockMutate,
    unlockMutate,
    setAutoTimeLockURLMutate,
    updateLockIdMutate,
    generatePassCodeForLockMutate,
    getActivePassCodeQuery,
    getPassCodeHistoryQuery,
    getLockStateQuery,
    getLockBatteryQuery,
    getAllLocksListQuery,
    deleteLockMutate,
    getLocksControlQuery,
    requestNewOperatorMutate,
    getPropertyDetailsQuery,
    disablePassCodeMutate,
    getLockDetailsQuery,
    getLockStatusQuery,
    geQrCodeQuery
  };
};

export { useLock };
