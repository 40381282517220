import styled from "styled-components"

export const DirectMessagesWrapper = styled.div`
	.containerCard {
		border-radius: 24px;
		background-color: #fff;
		padding: 16px;
		gap: 24px;
		height: calc(100dvh - 184px);
		overflow: hidden;
		> div {
			height: 100%;
			> div {
				height: 100%;
			}
		}
	}
`
