import styled from "styled-components"

export const SectionUserDetailsWrapper = styled.div`
	border: 1px solid #0000001a;
	border-radius: 16px;
	display: grid;
	grid-template-rows: auto 1fr;
	grid-template-columns: 1fr;
	width: 100%;
	height: 100%;
	margin-inline: 4px;
	overflow: hidden;
	.containerHeader {
		padding: 16px;
		border-bottom: 1px solid #0000001a;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		p {
			margin: 0;
			height: 35px;
			color: #435363;
			font-size: 20px;
			font-weight: 500;
			line-height: 24px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}
	}
	.containerInfoData {
		overflow-x: scroll;
	}
`
