import { createRoot } from 'react-dom/client';
import 'dayjs/locale/ar-sa.js';
import { RouterProvider } from 'react-router-dom';
import './i18n';
import './index.scss';
import { useRouterLinks } from '@hooks/useRouterLink';
import { AllProviders } from './utils/AllProviders';
import * as Sentry from "@sentry/react";
const mode = import.meta.env.VITE_MODE ?? "not-found"
const container = document.getElementById('root');

const root = createRoot(container!);
mode=='PROD' &&Sentry.init({
  dsn: "https://b017e0b58fd5ccbd61ff4f45bd1894e7@o4507923914424320.ingest.de.sentry.io/4507951769714769",
  integrations: [
    Sentry.browserTracingIntegration(), 
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})
 
const App = () => {

  const { router } = useRouterLinks();

  return <RouterProvider router={router} />;
};

root.render(
  <AllProviders>
    <App />
  </AllProviders>,
);