import { ChatMessagesWrapper } from "./styles"

interface IProps {
	getALLChat: {
		message: string
		sender: "guest" | "property" | "system"
		time: string
		id: string
	}[]
}
export default function ChatMessages({ getALLChat }: IProps) {
	return (
		<ChatMessagesWrapper>
			{getALLChat.map((item) => {
				return (
					<div key={item.id} className={`${item.sender}`}>
						<div>
							<p>{item.message}</p>
						</div>
					</div>
				)
			})}
		</ChatMessagesWrapper>
	)
}
