import styled from "styled-components"

export const PopupDeleteWrapper = styled.div`
	background-color: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	.containerQuestions {
		width: 60%;
		padding: 32px;
		background-color: #fff;
		border-radius: 16px;
		.header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 36px;
			p {
				font-size: 20px;
				font-weight: 700;
				line-height: 22px;
				margin: 0;
			}
			button {
				background-color: transparent;
				border: none;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 24px;
				height: 24px;
				font-size: 24px;
				cursor: pointer;
			}
		}
		.containerItem {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 8px;
			p {
				margin: 5px 0 0 0;
				font-size: 16px;
				font-weight: 400;
			}
		}

		.Footer {
			margin-top: 32px;
			button {
				background-color: #5B3FFF;
				color: #fff;
				border: none;
				width: 100%;
				padding: 16px;
				border-radius: 8px;
				font-size: 18px;
				font-weight: 700;
				line-height: 28px;
				cursor: pointer;
			}
		}
	}
	.errorMessage{
		font-size: 12px;
		color: red;
	}
`
